<template>
  <b-container>
    <b-card>
      <router-link :to="{name:'play/season-1'}">Season 1</router-link>
    </b-card>
  </b-container>
</template>

<script>
export default {}
</script>